import HomePageFaqData from '@/data/static/json/home_page_faq.json';
import PricingPageFaqData from '@/data/static/json/pricing_page_faq.json';
import ForPersonalUsePageFaqData from '@/data/static/json/for_personal_use_faq.json';
import ForEmployersPageFaqData from '@/data/static/json/for_employers_page_faq.json';
import ConsultationPageFaqData from '@/data/static/json/second_opinion_page_faq.json';
import SymptomCheckerPageFaqData from '@/data/static/json/symptom_checker_page_faq.json';
import AIHealthAssistantPageFaqData from '@/data/static/json/aI_health_assistant_page_faq.json';
import SymptomCheckerForMenPageFaqData from '@/data/static/json/symptom_checker_for_men_page_faq.json';
import ForInsuranceProvidersPageFaqData from '@/data/static/json/for_insurance_providers_page_faq.json';
import SymptomCheckerForChildPageFaqData from '@/data/static/json/symptom_checker_for_child_page_faq.json';
import SymptomCheckerForWomenPageFaqData from '@/data/static/json/symptom_checker_for_women_page_faq.json';
import SymptomCheckerForAdultsPageFaqData from '@/data/static/json/symptom_checker_for_adults_page_faq.json';
import ForHealthcareProfessionalsPageFaqData from '@/data/static/json/for_healthcare_professionals_page_faq.json';
import SupplementsPageFaqData from '@/data/static/json/supplements_page_faq.json';
import LabTestAnalysisPageFaqData from '@/data/static/json/lab_test_analysis_page_faq.json';
import BloodTestPageFaqData from '@/data/static/json/blood_test_page_faq.json';
import UrineTestPageFaqData from '@/data/static/json/urine_test_page_faq.json';
import PAPSmearPageFaqData from '@/data/static/json/pap_smear_page_faq.json';
import StoolTestPageFaqData from '@/data/static/json/stool_test_page_faq.json';
import SemenTestPageFaqData from '@/data/static/json/semen_analysis_page_faq.json';
import SwabTestPageFaqData from '@/data/static/json/swab_test_page_faq.json';

export const FAQSectionData = {
  home: {
    title: '',
    FAQData: HomePageFaqData,
  },
  'ai-doctor': {
    title: '',
    FAQData: AIHealthAssistantPageFaqData,
  },
  'ai-health-assistant': {
    title: '',
    FAQData: AIHealthAssistantPageFaqData,
  },
  consultation: {
    title: '',
    FAQData: ConsultationPageFaqData,
  },
  'consultation-landing': {
    title: '',
    FAQData: ConsultationPageFaqData,
  },
  experts: {
    title: '',
    FAQData: ConsultationPageFaqData,
  },
  'for-employers': {
    title: 'Frequently Asked Questions',
    FAQData: ForEmployersPageFaqData,
  },
  'for-healthcare-professionals': {
    title: '',
    FAQData: ForHealthcareProfessionalsPageFaqData,
  },
  'for-insurance-providers': {
    title: 'Frequently Asked Questions',
    FAQData: ForInsuranceProvidersPageFaqData,
  },
  'for-personal-use': {
    title: 'Frequently Asked Questions',
    FAQData: ForPersonalUsePageFaqData,
  },
  'symptom-checker': {
    title: '',
    FAQData: SymptomCheckerPageFaqData,
  },
  'symptom-checker-landing': {
    title: '',
    FAQData: [],
  },
  'symptom-checker-for-adults': {
    title: '',
    FAQData: SymptomCheckerForAdultsPageFaqData,
  },
  'symptom-checker-for-men': {
    title: '',
    FAQData: SymptomCheckerForMenPageFaqData,
  },
  'symptom-checker-for-women': {
    title: '',
    FAQData: SymptomCheckerForWomenPageFaqData,
  },
  'child-symptom-checker': {
    title: '',
    FAQData: SymptomCheckerForChildPageFaqData,
  },
  pricing: {
    title: '',
    FAQData: PricingPageFaqData,
  },
  supplements: {
    title: '',
    FAQData: SupplementsPageFaqData,
  },
  'lab-test-interpretation': {
    title: '',
    FAQData: LabTestAnalysisPageFaqData,
  },
  'blood-test': {
    title: '',
    FAQData: BloodTestPageFaqData,
  },
  'urine-test': {
    title: '',
    FAQData: UrineTestPageFaqData,
  },
  'pap-smear': {
    title: '',
    FAQData: PAPSmearPageFaqData,
  },
  'stool-test': {
    title: '',
    FAQData: StoolTestPageFaqData,
  },
  'semen-analysis': {
    title: '',
    FAQData: SemenTestPageFaqData,
  },
  'swab-test': {
    title: '',
    FAQData: SwabTestPageFaqData,
  },
};

export type FAQSectionDataByPageName = keyof typeof FAQSectionData;
